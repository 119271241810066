<template>
<div>
  <v-container>
    <v-layout wrap row xs12>
    <v-menu ref="dateFrom"
            lazy
            :close-on-content-click="false"
            v-model="dateFrom"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            min-width="290px"
            dark
            :return-value.sync="from">
        <v-text-field slot="activator"
                      label="С: "
                      v-model="from"
                      prepend-icon="mdi-calendar"
                      single-line
                      readonly></v-text-field>
        <v-date-picker locale="ru-ru" :first-day-of-week="1" v-model="from" @input="$refs.dateFrom.save(from)"></v-date-picker>
    </v-menu>
    <v-menu ref="dateTo"
            lazy
            :close-on-content-click="false"
            v-model="dateTo"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            min-width="290px"
            dark
            :return-value.sync="to">
        <v-text-field slot="activator"
                      label="По: "
                      v-model="to"
                      prepend-icon="mdi-calendar"
                      single-line
                      readonly></v-text-field>
        <v-date-picker locale="ru-ru" :first-day-of-week="1" v-model="to" @input="$refs.dateTo.save(to)"></v-date-picker>
    </v-menu>
    <v-select label="Выберите точку продажи"
      :items="places"
      item-text="name"
      item-value="id"
      v-model="selectedPlaceId"></v-select>
    <v-select label="Выберите статус заказа"
      :items="orderStates"
      item-text="text"
      item-value="value"
      v-model="selectedState"
      >
    </v-select>
    <v-btn color="info" @click="getOrders">Показать</v-btn>
    <v-btn color="info" @click="onReset">Очистить</v-btn>
    </v-layout>
    </v-container>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
  
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          color="green"
          title="Заказы"
          text="Статистика по заказам"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>
                <v-btn color="#1867c0!important" small flat icon :to="{ name: 'Заказ', params: { id: item.id } }" >
                  <v-icon>
                    mdi-alert-circle-outline
                  </v-icon>
                </v-btn>
              </td>
              <td>{{ item.created | date }} {{ item.created | time }}</td>
              <td>{{ item.paymentOrderId }}</td>
              <td>{{ item.userName }}</td>
              <td>{{ item.count }}</td>
              <td class="text-xs-right">{{ item.price }}</td>
              <td>{{ item.hasPayed ? 'Да' : 'Нет' }}</td>
              <td><span v-if="item.payedDate">{{ item.payedDate | date }} {{ item.payedDate | time }}</span></td>
              <td>{{ item.hasReceived ? 'Да' : 'Нет' }}</td>
              <td><span v-if="item.receivedDate">{{ item.receivedDate | date }} {{ item.receivedDate | time }}</span></td>
              
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <!-- <v-flex
        md12
      >
        <material-card
          color="green"
          flat
          full-width
          title="Table on Plain Background"
          text="Here is a subtitle for this table"
        >
          <v-data-table
            :headers="headers"
            :items="items.slice(0, 7)"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.country }}</td>
              <td>{{ item.city }}</td>
              <td class="text-xs-right">{{ item.salary }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex> -->
    </v-layout>
  </v-container>
</div>
</template>

<script>
export default {
  data: () => ({
    from: null,
    to: null,
    dateFrom: null,
    dateTo: null,
    places: [],
    selectedPlaceId: null,
    selectedState: 0,
    orderStates: [
      {text: 'Все', value: 0},
      {text: 'Не оплаченные', value: 1},
      {text: 'Оплаченные', value: 2},
      {text: 'Выданы', value: 3},
    ],
    headers: [
      {
        sortable: false,
        text: ''
      },
      
      {
        sortable: true,
        text: 'Дата',
        value: 'name'
      },
      {
        sortable: false,
        text: 'Payment Id',
        value: 'paymentOrderId'
      },
      {
        sortable: true,
        text: 'Покупатель',
        value: 'country'
      },
      {
        sortable: true,
        text: 'Количество',
        value: 'city'
      },
      {
        sortable: true,
        text: 'Цена',
        value: 'salary',
        align: 'right'
      },
      {
        sortable: true,
        text: 'Оплачен',
        value: 'hasPayed'
      },
      {
        sortable: true,
        text: 'Дата оплаты',
        value: 'payedDate'
      },
      {
        sortable: true,
        text: 'Получен',
        value: 'hasReceived'
      },
      {
        sortable: true,
        text: 'Дата получения',
        value: 'receivedDate'
      },
      
    ],
    items: [
      
    ]
  }),
  mounted () {
    let today = new Date(Date.now());
    this.from = today.toISOString().substr(0, 10)
    today.setDate(today.getDate() + 1)
    this.to = today.toISOString().substr(0, 10)
    this.getOrders()
    this.getPlaces()
  },
  // watch: {
  //   from: function (val) {
  //       this.getOrders();
  //   },
  //   to: function (val) {
  //       this.getOrders();
  //   },
  //   selectedState: function(val){
  //     this.getOrders();
  //   },
  //   selectedPlaceId: function(val){
  //     this.getOrders();
  //   }
  // },
  methods: {
    getOrders (){
      var options = {
          params: {
              from: this.from,
              to: this.to,
              placeId: this.selectedPlaceId,
              state: this.selectedState
          }
      };
      this.$http.get('/statistics/orders/list', options)
        .then(response => {
          this.items = response.data
        })
    },
    onReset() {
      let today = new Date(Date.now());
      this.from = today.toISOString().substr(0, 10)
      today.setDate(today.getDate() + 1)
      this.to = today.toISOString().substr(0, 10)
      this.selectedPlaceId = null;
      this.selectedState = 0;
      this.updateStats();
    },
    getPlaces (){
      this.$http.get('/places')
        .then(response => {
          this.places = response.data
          this.places.unshift({'id': null, name: 'Все'})
        })
    },
  }
}
</script>
